/* :root, :root.light {
  --clr-bg- : rgb(250, 250, 250);
  --clr-tx- : rgb(17, 21, 23);
  --clr-in- : rgb(133, 133, 133);
  --clr-wt- : rgb(255, 255, 255);
}

:root.dark{
  --clr-bg- : rgb(32, 44, 55);
  --clr-tx- : rgb(43, 57, 69);
  --clr-in- : rgb(43, 57, 69);
  --clr-wt- : rgb(255, 255, 255);
} */


@tailwind base;
@tailwind components;
@tailwind utilities;

*,*::after, *::before{
  padding: 0%;
  margin: 0%;
}

body {
  margin: 0;
  padding: 0%;
  font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
